body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'AmazonEmberBold';
  font-style: normal; /* Even though this says "normal" it means bold, as the font only contains bold */
  font-weight: 700;
  src: local('AmazonEmber-Bold'),
       url(/assets/AmazonEmber-Bold.d44c57c8349841beee3cc75f480a67fc.ttf);
}

@font-face {
  font-family: 'AmazonEmberCondensed';
  font-style: normal; /* Even though this says "normal" it means bold, as the font only contains bold */
  font-weight: 700;
  src: local('AmazonEmberCdRC-Bold'),
       url(/assets/AmazonEmberCdRC-Bold.2e34bbc4b134e3c7ba6d96931105cba8.ttf);
}

@font-face {
  font-family: 'AmazonEmber';
  font-style: normal;
  font-weight: normal;
  src: local('AmazonEmber_Rg'),
       url(/assets/AmazonEmber_Rg.b2fd0d6e747d7c2c7d60aa255817f3de.ttf);
}

@font-face {
  font-family: 'AmazonEmberSerif';
  font-style: normal;
  font-weight: normal;
  src: local('AmazonEmberSerif_Rg'),
       url(/assets/AmazonEmberSerif_Rg.95965095d727c550491855090103e3f9.ttf);
}

@font-face {
  font-family: 'AmazonEmberMedium';
  font-style: normal;
  font-weight: normal;
  src: local('Amazon-Ember-Medium'),
       url(/assets/Amazon-Ember-Medium.ae1211657d7c48bc3bcdfe36634e1532.ttf);
}